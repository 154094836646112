//import { rootUrl } from '@/utils/helpers/urlHelpers'
//import { useAuthStore } from '@/stores/auth.store'
//import { showSnackbar } from '@/utils/showSnackbar'

// Lazy-loaded components

// Subdomain components
const About = () => import('@/pages/welcome/AboutPage.vue')
const FreeAgents = () => import('@/pages/FreeAgentsPage.vue')
const Home = () => import('@/pages/welcome/HomePage.vue')
const Registration = () => import('@/pages/RegistrationPage.vue')
const Rules = () => import('@/pages/RulesPage.vue')
const Schedule = () => import('@/pages/SchedulePage.vue')

// Public components (not restricted to subdomains)
const NotFoundPage = () => import('@/pages/common/NotFoundPage.vue')

const routes = [
  {
    path: '/about',
    name: 'about',
    components: {
      default: About,
    },
    meta: {
      roles: ['ALL'],
      layout: 'BasicLayout'
    },
  },
  {
    path: '/',
    name: 'home',
    components: {
      default: Home,
    },
    meta: {
      roles: ['ALL'],
      layout: 'BasicLayout'
    },
  },
  {
    path: '/rules',
    name: 'rules',
    components: {
      default: Rules,
    },
    meta: {
      roles: ['ALL'],
      layout: 'BasicLayout'
    },
  },
  {
    path: '/registration',
    name: 'registration',
    components: {
      default: Registration,
    },
    meta: {
      roles: ['ALL'],
      layout: 'BasicLayout'
    },
  },
  {
    path: '/schedule',
    name: 'schedule',
    components: {
      default: Schedule,
    },
    meta: {
      roles: ['ALL'],
      layout: 'BasicLayout'
    },
  },
  {
    path: '/free-agents',
    name: 'freeAgents',
    components: {
      default: FreeAgents,
    },
    meta: {
      roles: ['ALL'],
      layout: 'BasicLayout'
    },
  },
  {
    path: '/:pathMatch(.*)',
    name: 'notFound',
    component: NotFoundPage,
    meta: {
      roles: ['ALL'],
      layout: 'LobbyLayout'
    }
  },
]

export default routes
