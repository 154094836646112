import { createRouter, createWebHistory } from 'vue-router'
import routes from '@/routes/tenant'
import { useAuthStore } from '@/stores/auth.store'
import { rootUrl } from '@/utils/helpers/urlHelpers'


const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, from) => {
  const authStore = useAuthStore()
  if (!authStore.userRoles.length) {
    // If there are no userRoles, it could be because the page was refreshed and the store is empty. In that case,
    // we need to fetch the current user to re-populate the store. There may be a better way to do this instead of
    // making extra requests for the current user.
    authStore.fetchCurrentUser()
  }

  // Used for shared/lobby pages like /login, /signup, etc.
  if (to.meta.forceRedirect && location.host != rootUrl().replace(/\//g, '')) {
    location.href = rootUrl() + to.name
  }
})

// For every visited path, check if user's role allows access. If not, redirect to their dashboard page.
// We use beforeResolve here (instead of guards that trigger earlier) to allow all the async route components
// to complete so that the authStore is populated with a user and roles.
router.beforeResolve(async (to, from) => {
  // The store needs to be created inside this block because pinia isn't installed until the router is installed: https://github.com/vuejs/pinia/discussions/971
  const authStore = useAuthStore()
  const userRoles = await authStore.userRoles

  if (to.meta.roles.includes('ALL')) {
    console.log('public route:', to.name)
  } else if (to.meta.roles.some((role) => userRoles.includes(role))) {
    console.log('route matched', to.name)
  } else {
    console.log('invalid route', to.name)
    // showSnackbar('You do not have access to that page', 'error')
    return { name: 'home' }
  }
})

export default router
